<template>
  <div>
    <b-pagination
      v-model="currentPage"
      style="color:#FF3333FF"
      :total-rows="rows"
      per-page="2"
      align="center"
      @change="qureyStrategyByPage"
    />
    <p>{{ currentPage }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      rows: 6
    }
  },
  methods: {
    qureyStrategyByPage() {
      console.log('out', this.currentPage)
      setTimeout(

        console.log(this.currentPage),
        500
      )
    }
  }
}

</script>
